import React from 'react'
import SearchBar from '../../components/search/SearchBar'
import Layout from '../sidenav/Layout';
import '../Dashboard/dashboard.css'

function Dashboard() {
  return (
    <Layout>
    <div className='dashboardGen'>
        <h3>Welcome to Able Jobs</h3>
        {/* <button>Contact us</button> */}
    </div>
    </Layout>
  )
}

export default Dashboard;