import w1 from '../../images/h2.jpg';
import w2 from '../../images/h6.jpg';
import w3 from '../../images/wr2.jpg';
import w4 from '../../images/wr12.jpg';
import w5 from '../../images/a3.jfif';
import w6 from '../../images/wr1.jpg';
import w7 from '../../images/wr12.jpg';
import w8 from '../../images/w4.250';
import w9 from '../../images/wr4.jpg';
import w10 from '../../images/wr7.jpg';
import c1 from '../../images/c1.jpg';
import c2 from '../../images/c2.jpg';
import c3 from '../../images/c3.jpg';
import c5 from '../../images/d6.jpg';
import c4 from '../../images/c4.jpg';
import c6 from '../../images/c5.jpg';
import c7 from '../../images/c6.jpg';
import c8 from '../../images/Capture1.JPG';
import c9 from '../../images/Capture2.JPG';
import c10 from '../../images/s2.jpg';

export const images = [
  {
    src: w1,
    original: w1,
    width: 320,
    height: 174,
    
    caption: "After Rain (Jeshu John - designerspics.com)",
  },
  {
    src: w2,
    original:w2,
    width: 320,
    height: 212,
    corporate: false,
    caption: "Boats (Jeshu John - designerspics.com)",
  },
  {
    src: w3,
    original: w3,
    width: 320,
    height: 212,
    corporate: false,
    caption: "Color Pencils (Jeshu John - designerspics.com)",
  },
  {
    src: w4,
    original: w4,
    height: 213,
    corporate: false,
    caption: "Red Apples with other Red Fruit (foodiesfeed.com)",
  },
  {
    src: w5,
    original: w5,
    width: 320,
    height: 183,
    corporate: false,
    caption: "37H (gratispgraphy.com)",
  },
  {
    src: w6,
    original: w6,
    width: 540,
    height: 320,
    corporate: false,
    caption: "8H (gratisography.com)",
  },
  {
    src:w7,
    original: w7,
    width: 320,
    height: 190,
    corporate: false,
    caption: "286H (gratisography.com)",
  },
  {
    src: w8,
    original: w8,
    width: 320,
    height: 148,
    corporate: false,
    caption: "315H (gratisography.com)",
  },
  {
    src:w9 ,
    original:w9 ,
    width: 320,
    height: 213,
    corporate: false,
    caption: "201H (gratisography.com)",
  },
  {
    src: w10,
    original: w10,
    alt: "Big Ben - London",
    width: 548,
    height: 320,
    corporate: false,
    caption: "Big Ben (Tom Eversley - isorepublic.com)",
  },
  {
    src: c1,
    original: c1,
    width: 320,
    height: 174,
    caption: "Corporate Event 1",
    corporate: true 
  },
  {
    src: c2,
    original: c2,
    width: 320,
    height: 212,
    caption: "Corporate Event 2",
    corporate: true 
  },
  {
    src: c3,
    original: c3,
    width: 320,
    caption: "Corporate Event 3",
    corporate: true 
  },
  {
    src: c4,
    original: c4,
    width: 320,
    height: 194,
    caption: "Corporate Event 2",
    corporate: true 
  },
  {
    src: c5,
    original: c5,
    alt: "Cosmos Flower",
    width: 320,
    height: 213,
    caption: "Corporate Event 2",
    corporate: true 
  },
  {
    src: c6,
    original: c6,
    width: 471,
    height: 320,
    caption: "Corporate Event 2",
    corporate: true 
  },
  {
    src: c7,
    original: c7,
    width: 320,
    height: 213,
    caption: "Corporate Event 2",
    corporate: true 
  },
  {
    src:c8,
    original: c9,
    width: 320,
    height: 213,
    caption: "Corporate Event 2",
    corporate: true 
  },
  {
    src: c10,
    original: c10,
    width: 320,
    height: 213,
    caption: "Corporate Event 2",
    corporate: true 
  },
  // {
  //   src: "https://c4.staticflickr.com/8/7476/28973628875_069e938525_b.jpg",
  //   original: "https://c4.staticflickr.com/8/7476/28973628875_069e938525_b.jpg",
  //   width: 320,
  //   height: 213,
  //   caption: "Time to Think (Tom Eversley - isorepublic.com)",
  // },
  // {
  //   src: "https://c6.staticflickr.com/9/8593/28357129133_f04c73bf1e_b.jpg",
  //   original: "https://c6.staticflickr.com/9/8593/28357129133_f04c73bf1e_b.jpg",
  //   width: 320,
  //   height: 179,
  //   tags: [
  //     { value: "Nature", title: "Nature" },
  //     { value: "Fauna", title: "Fauna" },
  //   ],
  //   caption: "Untitled (Jan Vasek - jeshoots.com)",
  // },
  // {
  //   src: "https://c6.staticflickr.com/9/8893/28897116141_641b88e342_b.jpg",
  //   original: "https://c6.staticflickr.com/9/8893/28897116141_641b88e342_b.jpg",
  //   width: 320,
  //   height: 215,
  //   tags: [{ value: "People", title: "People" }],
  //   caption: "Untitled (moveast.me)",
  // },
  // {
  //   src: "https://c1.staticflickr.com/9/8056/28354485944_148d6a5fc1_b.jpg",
  //   original: "https://c1.staticflickr.com/9/8056/28354485944_148d6a5fc1_b.jpg",
  //   width: 257,
  //   height: 320,
  //   caption: "A photo by 贝莉儿 NG. (unsplash.com)",
  // },
  // {
  //   src: "https://c7.staticflickr.com/9/8824/28868764222_19f3b30773_b.jpg",
  //   original: "https://c7.staticflickr.com/9/8824/28868764222_19f3b30773_b.jpg",
  //   width: 226,
  //   height: 320,
  //   caption: "A photo by Matthew Wiebe. (unsplash.com)",
  // },
];
