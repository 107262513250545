import * as React from 'react';
import Layout from '../sidenav/Layout';
import Paper from '@mui/material/Paper';

function EmployeeData(){
    return(
        <Layout>
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
       
      </Paper>
    </Layout>
    )

}
export default EmployeeData