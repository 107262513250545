import React from 'react'
import './vlogin.css'

function vlogin() {
  return (
    <div className='containers'>
      <input type='text'/>
    </div>
  )
}

export default vlogin